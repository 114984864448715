import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { RequesterService } from 'src/app/common/services/requester.service';
import { StatusService } from 'src/app/common/services/status.service';

interface APIResponse {
  error: boolean;
  statusCode: number;
  success: boolean;
  data: any;
  totalCount?: number;
}

@Component({
  selector: 'app-search-modal',
  templateUrl: './search-modal.component.html',
  styleUrls: ['./search-modal.component.scss']
})
export class SearchModalComponent implements OnInit {

  inputValue: string = "";
  options: string[] = [];
  optionGroups: any[] = [];
  searchText$: Subject<string> = new Subject<string>();
  isLoading = false;
  recentSearchedData: any;
  matchingCount: number = 0;
  constructor(
    private modalRef: NzModalRef,
    // private apiService: ApiService,
    private requestService: RequesterService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private message: NzMessageService,
    private statusService: StatusService
  ) {
    this.searchText$
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((value) => {
        console.log(value);
        if (!value) {
          this.initSearch();
        } else {
          this.storeRecentSearches(value);
          this.getSearchedData();
        }
      });
  }
  onInputClose(): void {
    if (this.inputValue !== "") {
      this.inputValue = "";
    } else {
      this.modalRef.destroy(true);
    }
  }

  ngOnInit(): void {
    this.initSearch();
  }

  initSearch(): void {
    this.recentSearchedData = {
      title: "Recent Searches",
      children: this.recentSearches.map((item: string) => {
        return { title: item, type: "recent" };
      }),
    };
    this.optionGroups = [
      // this.recentSearchedData
    ];
  }

  get recentSearches(): string[] {
    // Get them from local storage
    const recentSearches: string[] = [];
    if (localStorage.getItem("recentSearches")) {
      return JSON.parse(localStorage.getItem("recentSearches") || "");
    }
    return recentSearches;
  }

  storeRecentSearches(text: string): void {
    if (!text) {
      return;
    }
    // Store them in local storage
    const existingRecentSearches: string[] = this.recentSearches;
    existingRecentSearches.unshift(text);
    const uniqueRecentSearches: string[] = Array.from(
      new Set(existingRecentSearches)
    );
    // Store only 5 recent searches
    const recentSearches: string[] = uniqueRecentSearches.slice(0, 5);
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  }

  clearRecentSearches(): void {
    localStorage.removeItem("recentSearches");
    this.initSearch();
  }

  getSearchedData(): void {
    this.isLoading = true;
    const params: any = {};
    if (this.inputValue) {
      params.search = this.inputValue.trim();
      params.limit = 5;
      params.quick = 1;
    }
    this.requestService
      .request("get", "jobs/rfqs/global-job-search-vendor", params)
      .subscribe(
        (res: APIResponse) => {
          this.optionGroups = [
            {
              title: "Jobs",
              icon: "tool",
              children: res.data.map((item: any) => {
                return {
                  title: item.rfqNo,
                  rating: item.ratings,
                  ports: item.ports,
                  jobStatus: this.statusService.jobStatus[item.jobStatus],
                  jobStatusNumber: item.jobStatus,
                  type: "vendor",
                  id: item.id,
                };
              }),
            },
          ];

          this.matchingCount = res.totalCount;
          this.cdr.detectChanges();
          this.isLoading = false;
        },
        (err) => {
          console.log(err.error);
          this.message.error(err.error.message);
          this.isLoading = false;
        }
      );
  }

  openResource(event: any, item: any): void {
    console.log(item);
    if (item.type === "vendor") {
      event.preventDefault();
      console.log("Opening the resource");
      this.router.navigateByUrl(`/panel/bidding/${item.id}/rfq-summary`)

      // Open the resource
      this.modalRef.destroy(true);
    } else if (item.type === "recent") {
      // this.inputValue = item.title;
      // this.getSearchedData();
      event.preventDefault();
      this.searchText$.next(item.title + " ");
    }
  }

  openSearchResult(): void {
    if (this.inputValue) {
      this.router.navigate(["/panel/search"], {
        queryParams: { search: this.inputValue },
      });
      this.modalRef.destroy(true);
    }
  }

}
