const data = [
  {
    key: 'dashboard',
    title: 'Dashboard',
    route: '/panel/dashboard',
    icon: 'appstore'
  },
  {
    key: 'reportAndAnalytics',
    title: 'Analytics',
    route: "/panel/report-analysis/analysis",
    icon: 'highlight'
  },
  {
    key: 'detailchats',
    title: 'Chat',
    route: '/panel/chat',
    icon: 'message'
  },
  // {
  //   key: "joiningRequests",
  //   title: "Pending User Requests",
  //   route: "/panel/request/joining-requests",
  //   icon: "user",
  //   info: "Pending User Requests",
  // },
  {
    key: "teamMembers",
    title: "Team Members",
    route: "/corporate/team-members",
    icon: "user",
    info: "Team Members",
  },
  {
    key: "corporateProfile",
    title: "Corporate Profile",
    route: "/corporate/profile",
    icon: "shop",
    info: "Corporate Profile"
  },
 /*  {
    key: "paymentHistory",
    title: "Billing",
    route: "/panel/payments",
    icon: "dollar-circle",
    info: "Billing",
  } */
];

export { data };
