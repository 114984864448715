import { Component } from "@angular/core";
import { BnNgIdleService } from "bn-ng-idle";
import { environment } from "../environments/environment";
import { OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, first, take, takeWhile } from "rxjs/operators";
import { AuthService } from "./common/services/auth.service";
import { IdleUserDetectorService } from "./common/services/idle-user-detector.service";
import { SocketService } from "./common/services/socket.service";
import { NzModalService } from "ng-zorro-antd/modal";
import { ChangePasswordComponent } from "./auth/change-password/change-password.component";
import { RequesterService } from "./common/services/requester.service";
import { TermsConditionModalComponent } from "./common/components/terms-condition-modal/terms-condition-modal.component";
import { ISubscriptionState, StripeService } from "./common/services/stripe.service";
import { FreeTrialModalComponent } from "./common/components/free-trial-modal/free-trial-modal.component";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  sessionTimeOut$: any;
  currentUrl: string = '';
  exceptionUrls = ['/auth/choose-plan', '/auth/payment-success', '/auth/payment-failure'];
  myProfileData: any;
  initialPasswordChanged: boolean = false;
  vendorTermsAccepted: boolean = false;
  changePasswordModalOpen: boolean = false;
  vendorTermsModalOpen: boolean = false;
  subscriptionState: ISubscriptionState;
  freeTrialModalOpen: boolean = false;
  goToCorporateProfile: boolean = false;
  constructor(
    private socketService: SocketService,
    private _bnIdle: BnNgIdleService,
    private _router: Router,
    private _idleUserDetectorService: IdleUserDetectorService,
    private _authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private modalService: NzModalService,
    private router: Router,
    private requesterService: RequesterService,
    private stripeService: StripeService
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
        if (this._authService.isLoggedIn) {
          this.openModals();
        }
      }
    });

    this.stripeService.subscriptionState$.subscribe((state) => {
      this.subscriptionState = state;
      this.stripeService.showFreeTrial();
    });
  }

  getSessionId(): any {
    return document.cookie.match('(^|;)\\s*JSESSIONID\\s*=\\s*([^;]+)');
  }
  ngOnInit() {
    this.getCountryName();
    // console.log('App component', this.getSessionId());
    this.changeRouteDetect();
    this.socketService.connect();
    this.socketService.authentication();
    this._authService.startIdleTimer();


    this._authService.authState.subscribe((authState) => {
      // console.log('Auth state changed:', authState);
      if (authState) {
        this.getProfile();
        this.stripeService.updateSubscriptionState();
      }
    });

    if (this._authService.isLoggedIn) {
      // console.log('User is logged in');
      this.getProfile();
      this.stripeService.updateSubscriptionState();

      this.stripeService.showFreeTrialModal$.subscribe((show) => {
        if (show) {
          if (!this.freeTrialModalOpen && this.vendorTermsAccepted) {
            this.openFreeTrialModal();
          }
        }
      });
    }

  }

  openModals() {
    if (!this.myProfileData) {
      // console.log('Profile data not available');
      return;
    }
    if (!this.vendorTermsAccepted) {
      if (!this.vendorTermsModalOpen) {
        this.vendorTermsModalOpen = true;
        this.termsAndCondition();
      }
    } else if (!this.initialPasswordChanged) {
      if (!this.changePasswordModalOpen) {
        this.changePasswordModalOpen = true;
        this.openChangePasswordModal();
      }
    }
  }

  getProfile() {
    this.requesterService.request('get', 'auth/profile').subscribe(data => {
      // // console.log(data.data)
      this.myProfileData = data.data;
      const { initialPasswordChanged, vendorTermsAccepted } = this.myProfileData;
      this.initialPasswordChanged = initialPasswordChanged;
      this.vendorTermsAccepted = vendorTermsAccepted;
      this.openModals();
    });
  }


  //get route in every change --------
  changeRouteDetect(): void {
    // this.router.events
    //   .pipe(filter(event => event instanceof NavigationEnd))
    //   .subscribe((event: NavigationEnd) => {
    //     // console.log('Route changed **************************:', event.url);
    //     this.requesterService.request('post', 'generals/track-page-view', { page: event.url }).subscribe((apiResponse) => {
    //       // console.log('Page view tracked');
    //     }, err => {
    //       // console.log('Page view tracking failed');
    //     });
    //   });
  }

  openChangePasswordModal() {
    const modalRef = this.modalService.create({
      nzTitle: 'Set Password',
      nzContent: ChangePasswordComponent,
      nzComponentParams: {
        init: true
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzFooter: null
    });

    modalRef.afterClose.subscribe(result => {
      this.changePasswordModalOpen = false;
      this.initialPasswordChanged = true;
      this.goToCorporateProfile = true;
      // this.getProfile(true);
      this.openFreeTrialModal();
      // window.location.reload();
    });
  }


  termsAndCondition() {
    const modal = this.modalService.create({
      nzTitle: "Terms and Conditions",
      nzContent: TermsConditionModalComponent,
      nzWidth: "980px",
      nzStyle: { top: "20px" },
      nzMaskClosable: false,
      nzComponentParams: { data: this.myProfileData.company },
      nzClosable: false,
      nzFooter: null,

    });
    // Return a result when closed
    modal.afterClose.subscribe((result) => {
      this.vendorTermsModalOpen = false;
      if (result) {
        this.getProfile();
      }

    });
  }

  openFreeTrialModal() {
    if (this.subscriptionState && this.subscriptionState.active) {
      return;
    }
    this.modalService.create({
      nzTitle: '',
      nzContent: FreeTrialModalComponent,
      nzClosable: false,
      nzMaskClosable: false,
      nzOkText: 'Subscribe',
      nzCancelText: 'Remind me later',
      nzOnOk: () => {
        this.router.navigate(['/corporate/profile'], {queryParams: {billing: 1}});
      }
    }).afterClose.subscribe(() => {
      this.freeTrialModalOpen = false;
      if (this.goToCorporateProfile) {
        this.router.navigate(['/corporate/profile']);
        this.goToCorporateProfile = false;

      }
    });


  }


  getCountryName(){
    this.requesterService.httpClient.get('https://ipapi.co/json').subscribe((res) => {
      if(res) {
        localStorage.setItem('locationInfo', JSON.stringify(res));
      }
    })
  }


  ngOnDestroy() {
    this._idleUserDetectorService.stop();
  }

}
