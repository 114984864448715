import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { RequesterService } from './requester.service';
import { BehaviorSubject, Subject } from 'rxjs';
export interface ISubscriptionState {
  active: boolean;
  startDate?: string;
  endDate?: string;
  plan?: 'monthly' | 'yearly';
  amount?: number;
  cancelled?: boolean;
  canBid: boolean;
}
@Injectable({
  providedIn: 'root'
})
export class StripeService {
  private stripe: any;

  private subscriptionState: BehaviorSubject<ISubscriptionState> = new BehaviorSubject<ISubscriptionState>(null);
  public subscriptionState$ = this.subscriptionState.asObservable();

  private showFreeTrialModal: Subject<boolean> = new Subject<boolean>();
  public showFreeTrialModal$ = this.showFreeTrialModal.asObservable();

  constructor(private requesterService: RequesterService) { }

  updateSubscriptionState() {
    this.requesterService.request('get', 'payments/stripe/seller/subscription-state').subscribe((res: any) => {

      this.subscriptionState.next(res.data);
    });
  }


  async getStripe() {
    if (!this.stripe) {
      this.stripe = await loadStripe('pk_live_51P1MKNItVaMpopZmz3qnFurAWQQqTA7BGuOXKDneslc6HGWCEk6PM2NFHgjnMJNEGOhrrw2whzRFD9HxcFgAiFCK00C84W4wFn');
    }
    return this.stripe;
  }

  addCard(tokenId: string) {
    return this.requesterService.request('post', 'payments/stripe/seller/payment-methods', { token: tokenId }).toPromise();
  }

  showFreeTrial() {
    this.showFreeTrialModal.next(true);
  }
}
