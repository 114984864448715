import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class RequesterService {
  baseUrl = environment.apiUrl;
  stripeUrl = environment.stripeUrl;
  bearerToken: string;
  constructor(
    public httpClient: HttpClient
  ) {
    this.bearerToken = localStorage.getItem('bearerToken');
    console.log('local url******',this.baseUrl);
  }

  public fileRequest(endpoint: string, data?: any): Observable<any> {
    return this.httpClient.post(`${this.baseUrl}/api/${endpoint}`, data,
      { headers: {Authorization: `Bearer ${localStorage.getItem('bearerToken')}` } });
  }

  public request(method = 'get', endpoint: string, data?: any, authorised: boolean = true): Observable<any> {
    let observable: any;
    const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('bearerToken') };
    if (!authorised) {
      delete headers.Authorization;
    }
    switch (method) {
      case 'post':
        observable = this.httpClient.post(`${this.baseUrl}/api/${endpoint}`, data, { headers });
        break;
      case 'put':
        observable = this.httpClient.put(`${this.baseUrl}/api/${endpoint}`, data, { headers });
        break;
      case 'delete':
        observable = this.httpClient.delete(`${this.baseUrl}/api/${endpoint}`, { headers, params: data });
        break;
      default:
        observable = this.httpClient.get(`${this.baseUrl}/api/${endpoint}`, { params: data, headers });
    }
    return observable;
  }

  //Stripe Request
  public stripeRequest(method = 'get', endpoint: string, data?: any, authorised: boolean = true): Observable<any> {
    let observable: any;
    const headers = { 'Content-Type': 'application/json', Authorization: 'Bearer ' + localStorage.getItem('bearerToken') };
    if (!authorised) {
      delete headers.Authorization;
    }
    switch (method) {
      case 'post':
        observable = this.httpClient.post(`${this.stripeUrl}/api/${endpoint}`, data, { headers });
        break;
      case 'put':
        observable = this.httpClient.put(`${this.stripeUrl}/api/${endpoint}`, data, { headers });
        break;
      case 'delete':
        observable = this.httpClient.delete(`${this.stripeUrl}/api/${endpoint}`, { headers });
        break;
      default:
        observable = this.httpClient.get(`${this.stripeUrl}/api/${endpoint}`, { params: data, headers });
    }
    return observable;
  }

}
