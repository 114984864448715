import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzUploadFile } from "ng-zorro-antd/upload";
import { RequesterService } from "src/app/common/services/requester.service";
import { currencies } from "src/app/common/data/currencies";
import { environment } from "src/environments/environment";
import { countries } from "src/app/common/data/countries";
import { ConfigurationService } from "src/app/common/services/configuration.service";
import { debounceTime, Subject, Observable, Observer, of } from "rxjs";
import { NzMessageService } from "ng-zorro-antd/message";
import { count } from "console";
import { NzSelectComponent } from "ng-zorro-antd/select";


@Component({
  selector: "app-profile-modal",
  templateUrl: "./profile-modal.component.html",
  styleUrls: ["./profile-modal.component.scss"],
})
export class ProfileModalComponent implements OnInit {
  @Input() data: any;
  bankForm: FormGroup;
  accreditationForm: FormGroup;
  generalInfoForm: FormGroup;
  bankDetails: boolean = false;
  accreditationDetails: boolean = false;
  generalInfoDetails: boolean = false;
  serviceAndEquipmentDetails: boolean = false;
  serviceAndEquipmentForm: FormGroup;
  services: any[] = [];
  equipments: any[] = [];
  brands: any[] = [];
  ports: any[] = [];
  companyDetails: boolean = false;
  companyDetailsForm: FormGroup;
  companyData: any;
  currencies = currencies;
  countries = countries;
  existingData: any;
  mediaUploadURL = environment.apiUrl + "/api/media";
  mediaBaseUrl = ConfigurationService.mediaBaseUrl;
  loading = false;
  avatarUrl: string | undefined = "";

  images: any[] = [];

  customHeaders: any = {
    Authorization: "Bearer " + localStorage.getItem("bearerToken"),
  };

  fileList: NzUploadFile[] = [];
  previewImage: string | undefined = "";
  previewVisible = false;
  countryWisePorts: any = {};

  countryWiseRegions: any = {};
  selectedPorts: any[] = [];
  selectedPortCountries: string[] = [];
  selectedRegions: string[] = [];

  allServices: any[] = [];
  serviceSearch = '';
  serviceSearchObs = new Subject<string>();

  allEquipments: any[] = [];
  equipmentSearch = '';
  equipmentSearchObs = new Subject<string>();

  allBrands: any[] = [];
  brandSearch = '';
  brandSearchObs = new Subject<string>();

  allParts: any[] = [];
  partsSearch = '';
  partsSearchObs = new Subject<string>();

  isPortLoading: boolean = false;
  listOfPorts: any[] = [];
  searchPort$: Subject<string> = new Subject<string>();
  portSelection: string = '';
  @ViewChild('currSelectPort') currSelectPort: NzSelectComponent;
  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private msg: NzMessageService
  ) {
    this.companyDetailsForm = this.fb.group({
      companyName: ["", Validators.required],
      address: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobile: [""],
      countryName: ["", Validators.required],
      zipCode: ["", Validators.required],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      companyLogo: [""],
      removeCompanyLogo: [""],
      invoiceCurrency: ['USD', Validators.required],
      websiteUrl: [""],
    });
    //this.companyDetailsForm.get("email")?.disable();
    this.generalInfoForm = this.fb.group({
      ports: [[],],
      portCountries: [[],],
      portRegions: [[],],
    });
    this.serviceAndEquipmentForm = this.fb.group({
      serviceName: [""],
      brands: [""],
      equipments: [""],
      parts: [""],
    });

    this.accreditationForm = this.fb.group({
      // Accreditation details
      _id: [""],
      certification: ["", Validators.required],
      files: [[]],
    });

    this.bankForm = this.fb.group({
      // bank details
      _id: [""],
      beneficiaryName: ["", Validators.required],
      bankName: ["", Validators.required],
      bankAddress: ["", Validators.required],
      bankAccountNumber: ["", Validators.required],
      branchCode: ["", Validators.required],
      swiftCode: ["", Validators.required],
      ibanCode: ["", Validators.required],
      ifscCode: ["", Validators.required],
      routingCode: ["", Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    console.log("modal data", this.data);

    this.serviceSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      this.serviceSearch = searched;
      this.getServices();
    });

    this.equipmentSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      this.equipmentSearch = searched;
      this.getEquipments();
    });

    this.brandSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      this.brandSearch = searched;
      this.getBrands();
    });

    this.partsSearchObs.pipe(debounceTime(1000)).subscribe(searched => {
      this.partsSearch = searched;
      this.getParts();
    });

    if (
      this.data.argument === "Add Bank" ||
      this.data.argument === "Update Bank"
    ) {
      this.bankDetails = true;
      if (this.data.argument === "Update Bank") {
        this.patchBankDetails();
      }
    }
    if (
      this.data.argument === "Add Accreditation" ||
      this.data.argument === "Update Accreditation"
    ) {
      this.accreditationDetails = true;

      this.patchAccreditationDetails();
    }
    if (this.data.argument === "Update Company") {
      console.log("update company check", this.data);
      this.companyDetails = true;
      //this.getMyCompanyDetails();
      this.patchCompanyDetails();
    }
    if (this.data.argument === "Service Area") {
      this.generalInfoDetails = true;
      //await this.getMyCompanyDetails();
      //await this.getPorts();
      this.selectedPorts = this.data?.value?.ports;
      this.selectedPortCountries = this.data?.value?.portCountries;
      this.selectedRegions = this.data?.value?.portRegions;
      this.updateCountryWisePorts();
    }
    if (this.data.argument === "Equipment, Brands & Parts") {
      this.serviceAndEquipmentDetails = true;

      this.patchServiceAndEquipmentDetails();
    }

    this.getPorts();

    this.searchPort$.pipe(debounceTime(500)).subscribe((search) => {
      this.isPortLoading = true;
      this.getPorts(search);
    });


  }

  // for company logo upload

  beforeUploadLogo = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng  = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.msg.error('You can only upload JPG, png file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size! / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 20MB!');
        observer.complete();
        return;
      }
      observer.next(isJpgOrPng && isLt2M);
      observer.complete();
    });
  handleChangeLogo(info: { file: NzUploadFile }): void {
    switch (info.file.status) {
      case 'uploading':
        this.loading = true;
        break;
      case 'done':
        // Get this url from response in real world.

        this.loading = false;
        this.avatarUrl = info.file.response.data.path;
        this.companyDetailsForm.patchValue({
          companyLogo: this.avatarUrl,
        })

        break;
      case 'error':

        this.loading = false;
        break;
    }
  }




  //remove company logo
  removeLogo(path: string): void {
    console.log("remove company logo", path);
    if (path) {
      // this.removeCompanyLogo = path;
      this.companyDetailsForm.patchValue({
        removeCompanyLogo: path,
      });
      this.companyDetailsForm.patchValue({
        companyLogo: "",
      });
      this.companyDetailsForm.updateValueAndValidity();
      this.avatarUrl = "";
    }
    // api call to remove logo

    const finalObj = {
      address: this.companyDetailsForm.value.address,
      companyLogo: '',
      companyName: this.companyDetailsForm.value.companyName,
      countryName: this.companyDetailsForm.value.countryName,
      FirstName: this.companyDetailsForm.value.firstName,
      LastName: this.companyDetailsForm.value.lastName,
      mobile: this.companyDetailsForm.value.mobile,
      zipCode: this.companyDetailsForm.value.zipCode,
      removeCompanyLogo: path,
      invoiceCurrency: this.companyDetailsForm.value.invoiceCurrency || 'USD',
      websiteUrl: this.companyDetailsForm.value.websiteUrl || null,

      section: "PROFILE"



    }

    this.requesterService.request('post', 'vendor-companies/update-section', finalObj).subscribe({
      next: (data) => {
        this.msg.success('Logo removed successfully');
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log('Request completed');
      }
    });




  }





  beforeUpload = (file: NzUploadFile, _fileList: NzUploadFile[]): Observable<boolean> =>
    new Observable((observer: Observer<boolean>) => {
      const isJpgOrPng = file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg';
      if (!isJpgOrPng) {
        this.msg.error('You can only upload JPG, png file!');
        observer.complete();
        return;
      }
      const isLt2M = file.size! / 1024 / 1024 < 20;
      if (!isLt2M) {
        this.msg.error('Image must smaller than 20MB!');
        observer.complete();
        return;
      }
      observer.next(true);
      observer.complete();
    });



  handleRemove = (file: NzUploadFile) => {
    this.fileList = this.fileList.filter((item) => item.uid !== file.uid);
  };

  handleChange(info: any): void {
    this.fileList = info.fileList;
    console.log("file list", this.fileList, 'images', this.images);
    // const fileList = info.fileList;
    // this.fileList = [];
    // // 2. read from response and show file link
    this.loading = true;
    if (info.file.response) {
      this.loading = false;
      info.file.url = info.file.response.url;



      // push the response data to the files array
      this.fileList.push(info.file.response.data.path);
      this.images.push(info.file.response.data.path);

      this.accreditationForm.patchValue({
        files: this.images
      });
      //this.images = this.fileList.map((x) => x.response?.data.path);
    }
  }

  handlePreview = async (file: NzUploadFile) => {
    this.previewImage = file.url || file.thumbUrl;
    console.log("preview image", this.previewImage);
    this.previewVisible = true;
  };

  handleCancel = () => (this.previewVisible = false);
  removeImage(index: number, path: string) {
    console.log('remove image', index);
    this.images.splice(index, 1);
    this.fileList.splice(index, 1);
    this.accreditationForm.patchValue({
      files: this.images
    });
    // api call to remove image

    const removeObj = {
      certification: this.accreditationForm.value.certification,
      removeCertificate: path,
      files: this.images,

    }

    this.requesterService.request('post', 'vendor-companies/update-certificates', removeObj).subscribe({
      next: (data) => {
        this.msg.success('File removed successfully');
      },
      error: (error) => {
        console.log(error);
      },
      complete: () => {
        console.log('Request completed');
      }
    });




  }

  async getMyCompanyDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.requesterService
          .request("get", "vendor-companies/my-company")
          .subscribe(
            (res) => {
              console.log("company data", res);
              this.companyData = res.data;

              this.companyDetailsForm.patchValue({
                companyName: res?.data?.companyName,
                address: res?.data?.basicDetails?.address,
                email: res?.data?.owner?.email,
                phone: res?.data?.basicDetails?.phone,
                websiteUrl: res?.data?.websiteUrl,
              });

              this.bankForm.patchValue({
                beneficiaryName: res?.data?.bankDetails?.beneficiaryName,
                bankName: res?.data?.bankDetails?.bankName,
                bankAddress: res?.data?.bankDetails?.bankAddress,
                bankAccountNumber: res?.data?.bankDetails?.bankAccountNumber,
                branchCode: res?.data?.bankDetails?.branchCode,
                swiftCode: res?.data?.bankDetails?.swiftCode,
                ibanCode: res?.data?.bankDetails?.ibanCode,
                ifscCode: res?.data?.bankDetails?.ifscCode,
                routingCode: res?.data?.bankDetails?.routingCode,
              });
              // this.services = res?.data?.skills?.serviceTypes;
              // this.equipments = res?.data?.skills?.equipmentTypes;
              // this.brands = res?.data?.skills?.brands;

              // this.serviceAndEquipmentForm.patchValue({
              //   serviceName: res?.data?.skills?.serviceTypes,
              //   brands: res?.data?.skills?.brands,
              //   equipments: res?.data?.skills?.equipmentTypes,
              // });
              this.accreditationForm.patchValue({
                certification: res?.data?.accreditation?.accreditations,
                files: res?.data?.skills?.accreditations?.files.map(
                  (x) => x.path
                ),
              });
              resolve();
            },
            (error) => {
              console.log(error);
            }
          );
      } catch (e) {
        console;
      }
    });
  }

  searchPorts(value: string): void {
    console.log("search ports", value);
    this.searchPort$.next(value);
  }

  onCountrySelect(event: any, i: number): void {
    console.log("country select", event, i, this.selectedPortCountries);
    this.selectedPortCountries.push(this.listOfPorts[i].country);
    console.log("selected port countries", this.selectedPortCountries);
    this.updateCountryWisePorts();
    console.log("country wise ports", this.currSelectPort);
    this.currSelectPort.setOpenState(false);
  }

  onPortSelect(event: any): void {
    const allPorts = this.listOfPorts.map((x) => x.ports).flat();
    const selectedPort = allPorts.find((x) => x._id === event);
    if (selectedPort) {
      if(selectedPort.type === 'port') {
        this.selectedPorts.push(selectedPort);
      } else {
        this.selectedRegions.push(selectedPort)
      }
      this.portSelection = '';
      this.updateCountryWisePorts();
    }
  }
  getPorts(search?: string): void {
    try {
      console.log("company data", this.companyData);
      let params: any = {};
      if (search) {
        params.search = search;
      }

      try {
        if(localStorage.getItem('locationInfo')) {
        const locationInfo = JSON.parse(localStorage.getItem('locationInfo'))
          if(locationInfo && locationInfo.country_name){
            // console.log(locationInfo)
            params.locale = locationInfo.country_name;
          }
        }
      } catch (error) {
      }

      // get api call
      this.requesterService
        .request("post", "master-resources/ports/ports-regions-by-country", params)
        .subscribe((res) => {
          this.listOfPorts = res.data;
          this.isPortLoading = false;
        }, err => {
          console.log(err);
          this.isPortLoading = false;
        });
    } catch (e) {
      console.log(e);
    }
  }

  patchCompanyDetails(): void {
    console.log("company data", this.data);
    this.companyDetails = true;
    this.companyDetailsForm.patchValue({
      companyName: this.data?.value?.companyName,
      address: this.data?.value?.basicDetails?.address,
      email: this.data?.value?.user?.email,
      mobile: this.data?.value?.user?.mobile,
      firstName: this.data?.value?.user?.firstName,
      lastName: this.data?.value?.user?.lastName,
      countryName: this.data?.value?.basicDetails?.countryName,
      zipCode: this.data?.value?.basicDetails?.zipCode,
      companyLogo: this.data?.value?.basicDetails?.companyLogo,
      invoiceCurrency: this.data?.value?.invoiceCurrency || 'USD',
      websiteUrl: this.data?.value?.websiteUrl || null,

    });
    this.avatarUrl = this.data?.value?.basicDetails?.companyLogo;
  }

  patchBankDetails(): void {
    this.bankDetails = true;
    this.bankForm.patchValue(this.data.value);
  }

  patchAccreditationDetails(): void {

    this.accreditationDetails = true;
    this.accreditationForm.patchValue(this.data.value);
    if (this.data?.value?.files?.length > 0) {
      this.images = this.data.value.files;
    }
  }

  patchServiceAndEquipmentDetails(): void {
    this.serviceAndEquipmentDetails = true;


    this.services = this.data?.value?.serviceTypes;
    this.equipments = this.data?.value?.equipmentTypes;
    this.brands = this.data?.value?.brands;
    this.serviceAndEquipmentForm.patchValue({
      serviceName: this.data?.value?.serviceTypes,
      equipments: this.data?.value?.equipmentTypes,
      brands: this.data?.value?.brands,
      parts: this.data?.value?.parts,
    });
  }

  removeCountry(country: string): void {
    this.selectedPortCountries = this.selectedPortCountries.filter(
      (x) => x !== country
    );
    this.updateCountryWisePorts();
  }

  removePort(port: any): void {
    this.selectedPorts = this.selectedPorts.filter((x: any) => x._id !== port._id);
    this.updateCountryWisePorts();
  }
  removeRegion(region: any): void {
    this.selectedRegions = this.selectedRegions.filter((x:any) => x._id !== region._id);
    this.updateCountryWisePorts();
  }


  updateCountryWisePorts(): void {
    this.countryWisePorts = {};
    console.log("selected ports", this.selectedPorts);
    this.selectedPorts.forEach((port: any) => {
      if (this.countryWisePorts[port.country]) {
        this.countryWisePorts[port.country].push(port);
      } else {
        this.countryWisePorts[port.country] = [port];
      }
    });

    this.countryWiseRegions = {};
    this.selectedRegions.forEach((region: any) => {
      if (this.countryWiseRegions[region.country]) {
        this.countryWiseRegions[region.country].push(region);
      } else {
        this.countryWiseRegions[region.country] = [region];
      }
    })

    for (const pc of this.selectedPortCountries) {
      this.countryWisePorts[pc] = [];
    }

    console.log("country wise ports", this.countryWisePorts);

    this.generalInfoForm.patchValue({
      ports: this.selectedPorts.map((x: any) => x._id),
      portCountries: this.selectedPortCountries,
      portRegions: this.selectedRegions,
    });
  }

  getServices() {
    const params: any = {
      limit: 20,
    };
    if (this.serviceSearch) {
      params.search = this.serviceSearch;
    }
    this.requesterService.request('get', `master-resources/services`, params).subscribe(data => {
      this.allServices = data.data.map((item: any) => item.serviceName);
    });
  }

  getEquipments() {
    const params: any = {
      limit: 20,
    };
    if (this.equipmentSearch) {
      params.search = this.equipmentSearch;
    }
    this.requesterService.request('get', `master-resources/equipment`, params).subscribe(data => {
      this.allEquipments = data.data.map((item: any) => item.equipmentName);
    });
  }

  getBrands() {
    const params: any = {
      limit: 20,
    };
    if (this.brandSearch) {
      params.search = this.brandSearch;
    }
    this.requesterService.request('get', `master-resources/brands`, params).subscribe(data => {
      this.allBrands = data.data.map((item: any) => item.brandName);
    });
  }

  getParts() {
    const params: any = {
      limit: 20,
    };
    if (this.partsSearch) {
      params.search = this.partsSearch;
    }
    this.requesterService.request('get', `master-resources/parts`, params).subscribe(data => {
      this.allParts = data.data.map((item: any) => item.partName);
    });
  }




}


